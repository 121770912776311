import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Banner from "../components/Banner"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import Carousesl from "../components/Carousel"
import Menu from "../components/Menu"
import Info from "../components/Info"

import { makeStyles } from "@material-ui/core/styles"

const PalmdalePage = ({ data, location }) => {
  const classes = useStyles()
  const {
    name,
    url,
    keywords,
    seoDescription,
    phone,
    isBannerOn,
    bannerTop,
    bannerLineFull,
    bannerMobile1,
    bannerMobile2,
  } = data.contentfulCompanyInfo

  const { edges } = data.allContentfulMenu

  return (
    <Layout>
      <SEO
        title={name}
        url={url}
        defaultUrl={url}
        keywords={keywords}
        seoDescription={seoDescription}
        image={data.contentfulNavbar.logo.fluid.src}
      />
      {isBannerOn && (
        <div className={classes.BannerContainer}>
          <Banner
            bannerTop={bannerTop}
            bannerLineFull={bannerLineFull}
            bannerMobile1={bannerMobile1}
            bannerMobile2={bannerMobile2}
          />
        </div>
      )}
      <div className={classes.NavContainer}>
        <Navbar
          logo={data.contentfulNavbar}
          company="/boomboomchicken"
          resturant="Boom Boom Chicken"
          phone={phone}
          location={location}
        />
      </div>
      <h1 hidden aria-hidden="true">
        {data.contentfulNavbar.logo.name}
      </h1>
      <Carousesl images={data.contentfulBanner} />
      <div className={classes.contentContainer}>
        <Menu
          flavors={edges[0].node}
          main={edges[1].node}
          sides={edges[2].node}
          resturant="Boom Boom Chicken"
        />
        <Info info={data.contentfulCompanyInfo} />
      </div>
      <Footer />
    </Layout>
  )
}

export default PalmdalePage

PalmdalePage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

const useStyles = makeStyles(theme => ({
  contentContainer: {
    maxWidth: "1200px",
    margin: "auto",
  },
  ////// Banner CSS
  BannerContainer: {
    zIndex: "1001",
    top: 0,
    position: "sticky",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
    },
  },
  // NavContainer: {
  //   position: "relative",
  //   "@media (max-width: 731px)": {
  //     zIndex: "1000",
  //     top: 60,
  //     position: "sticky",
  //   },
  //   "@media (max-width: 600px)": {
  //     zIndex: "1000",
  //     top: 75,
  //     position: "sticky",
  //   },
  //   "@media (max-width: 330px)": {
  //     zIndex: "1000",
  //     top: 150,
  //     position: "sticky",
  //   },
  // },
  ////// Banner CSS
}))

export const palmdaleQuery = graphql`
  {
    contentfulCompanyInfo(name: { eq: "Boom Boom Chicken" }) {
      name
      url
      keywords
      seoDescription
      streetAddress
      cityStateZip
      phone
      googleMaps
      doordash
      postmates
      grubhub
      isBannerOn
      bannerTop
      bannerLineFull
      bannerMobile1
      bannerMobile2
      businessHours {
        childMarkdownRemark {
          html
        }
      }
    }
    contentfulNavbar(name: { eq: "Boom Boom Chicken" }) {
      id
      name
      logo {
        fluid {
          ...GatsbyContentfulFluid_noBase64
        }
      }
    }
    contentfulBanner(title: { eq: "Boom Boom Chicken" }) {
      photo {
        fluid(quality: 90, maxHeight: 800) {
          ...GatsbyContentfulFluid_noBase64
        }
        id
        title
        description
      }
    }
    allContentfulMenu(
      filter: { name: { regex: "/BB/" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          name
          menu {
            Wings {
              Combo {
                deal
                description
                name
                price
              }
              For_Two {
                deal
                description
                name
                price
              }
              For_Groups {
                description
                name
                price
              }
              For_Catering {
                name
              }
            }
            Tenders {
              Combo {
                deal
                description
                name
                price
              }
              For_Two {
                deal
                description
                name
                price
              }
              For_Groups {
                description
                name
                price
              }
              For_Catering {
                name
                price
              }
            }
            Sandwiches {
              Chicken_Sandwich {
                description
                name
                price
              }
            }
            flavors {
              description
              name
              spice_level
            }
            sides {
              name
              price
            }
            bomb_extras {
              name
              price
            }
            drinks {
              name
              price
            }
          }
        }
      }
    }
  }
`

// filter: { name: { regex: "/BB/" } }
// allContentfulMenu(
//   filter: { name: { regex: "/BB/" } }
//   sort: { fields: name }
// ) {
//   edges {
//     node {
//       name
//       menu {
//         Wings {
//           Meals {
//             deal
//             boneless
//             bone_in
//             description
//             name
//           }
//           For_2 {
//             deal
//             bone_in
//             boneless
//             description
//             name
//           }
//           Family_Size {
//             boneless
//             bone_in
//             description
//             name
//           }
//           For_Catering {
//             name
//             price
//           }
//         }
//         Tenders {
//           Meals {
//             deal
//             price
//             description
//             name
//           }
//           For_2 {
//             deal
//             price
//             description
//             name
//           }
//           Family_Size {
//             price
//             description
//             name
//           }
//           For_Catering {
//             name
//             price
//           }
//         }
//         flavors {
//           description
//           name
//           spice_level
//         }
//         bomb_extras {
//           name
//           price
//         }
//         drinks {
//           name
//           price
//         }
//         sides {
//           name
//           price
//         }
//       }
//     }
//   }
// }
// }
// `
